export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser le match"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy & Sell"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Articles"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Region"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bid"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ask"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheteur"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tous"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnel"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler une erreur"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alertes"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres en cours"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Appliquer"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Origine du produit"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min :"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max :"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déconnexion"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search & Trading"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wallet"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix TTC"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode nuit"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode clair"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des transactions"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matchs"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des transactions"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du compte"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout effacer"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune notifications pour le moment"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas faire de clic droit"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de la transaction"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous effectuez trop de requêtes"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint votre limite de consultation journalière. Vérifiez votre compte pour ne plus être restreint."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez votre saisie : "])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur, réessayez plus tard"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vous reconnecter"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez valider votre email et téléphone avant d'effectuer cette action"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas autorisé à faire cette action"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service indisponible, veuillez contacter le service client"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'action a été annulée car le temps d'attente a été dépassé. Merci de réessayer"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier si tous les champs obligatoires ont été remplis correctement"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code envoyé"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attention, le code est valable 15 minutes"])},
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler un problème"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre saisie ou contactez le service client"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie réussie"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer :"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir une langue"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EN"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anglais"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagnol"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polonais"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemand"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tchèque"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Danois"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grecque"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongrois"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonais"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coréen"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugais"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RU"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Russe"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovaque"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinois"])}
    }
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez avoir plus de 18 ans pour visiter ce site"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre âge pour entrer."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai plus de 18 ans"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'ai moins de 18 ans"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En accédant à ce site, vous acceptez les "])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions d'utilisation "])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et "])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["la politique de confidentialité."])}
  },
  "home": {
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amateur, collectionneur ou professionnel, The Exchange Platform est fait pour vous : sécurité optimale, transparence totale et frais transactionnels réduits, avec 70 sources de prix historiques couvrant des milliers de références de bouteilles. "])},
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une révolution en soi"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La référence pour acheter, vendre et échanger vos spiritueux"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amateur de whisky, collectionneur de cognac ou professionnel en recherche de perfection, The Exchange Platform est pour vous. Sécurité optimale, transparence totale et frais réduits : découvrez le trading de spiritueux en toute sérénité."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transactions sécurisées"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais réduits 2.5% TTC"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptes gratuits 100% vérifiés"])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez le trading de spiritueux en toute sérénité."])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que vous soyez un particulier ou un professionnel, explorez notre agrégat de +70 sources de prix sur des milliers de références de spiritueux !"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer votre compte gratuit sans abonnement"])},
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading accessible sans abonnement !"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat/Vente : vos transactions sécurisées et transparentes"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grâce à The Exchange Platform, vendez ou achetez en continu en toute simplicité. Accédez facilement à l’ensemble des offres disponibles grâce à nos références standardisées, qui facilitent la comparaison et garantissent la transparence. Trouvez ce que vous cherchez ou diffusez votre intérêt : TEP vous permet de matcher vos besoins avec les recherches des contreparties, que vous soyez acheteur ou vendeur. Gagnez du temps, optimisez vos transactions et traitez toujours au meilleur prix. Avec une interface intuitive et des transactions sécurisées, TEP simplifie le trading des spiritueux, offrant une expérience rapide, fiable et efficace pour les amateurs comme pour les professionnels."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échange de bouteilles : sécurisé et garanti sans risque de contrepartie"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP est la seule plateforme qui offre la possibilité d’échanger des bouteilles en toute sécurité grâce à nos solutions de cash collatéralisé ou de dépot entrepot. Ce système garantit une protection totale : aucun risque de contrepartie, car l’argent est sécurisé chez notre prestataitre en services de paiements jusqu’à la confirmation des livraisons respectives des bouteilles. Cette fonctionnalité révolutionne le marché des spiritueux en apportant une transparence et une fiabilité inédites. Que vous soyez collectionneur ou amateur, l'Échange vous permet d’élargir ou d’optimiser votre collection sans aucune inquiétude quant à la sécurité des transactions. Echangez enfin vos spiritueux en toute sérénité, dans un environnement moderne et sécurisé."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade : Sécurisez vos transactions externes en toute simplicité"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le Block Trade, une fonctionnalité exclusive de TEP, vous permet de sécuriser vos transactions d'achat/vente en quelques clics. Grâce à cette innovation, un lien unique est généré pour chaque transaction, garantissant une exécution fiable et sécurisée. Que vous soyez acheteur ou vendeur, Block Trade élimine les risques associés aux échanges externes directs en offrant un cadre transparent et sécurisé. Cette fonctionnalité intègre également des outils avancés pour confirmer les conditions de la transaction, vérifier les comptes et garantir une exécution fluide à chaque étape. Découvrez une nouvelle manière de commercer vos spiritueux, avec la tranquillité d'esprit et la fiabilité qu'offre TEP."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed : votre hub gratuit d'informations dédié aux spiritueux"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restez informé des dernières actualités du secteur, des nouvelles sorties, des événements majeurs, et des tendances du marché grâce au Newsfeed de TEP. Cette fonctionnalité vous permet de suivre en temps réel tout ce qui impacte l'univers des spiritueux, avec des informations provenant des sites les plus pertinents et reconnus du secteur. Le Newsfeed vous offre une vision complète et agnostique de l'actualité, pour vous tenir au courant des évolutions du marché en un seul endroit."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review : votre espace d’analyse et de découverte"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorez gratuitement des contenus de dégustations avec le Blog & Review de TEP. Retrouvez des articles approfondis, des analyses pointues et des avis sur les spiritueux.Grâce à des reviews provenant des experts du secteur et des passionnés, le Blog & Review vous permet d’accéder à des perspectives uniques et des recommandations fiables. Que ce soit pour découvrir de nouvelles bouteilles, comprendre les tendances du marché ou approfondir vos connaissances, ce flux est conçu pour enrichir votre expérience dans l’univers des spiritueux. "])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Events Browser : votre guide des événements dédiés aux spiritueux"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec le Events Browser gratuit de TEP, ne manquez plus aucun rendez-vous important dans le monde des spiritueux. Découvrez les salons et événements exclusifs, où que vous soyez. Grâce à une sélection soigneusement mise à jour, le Events Browser vous propose les événements les plus pertinents du secteur, que vous soyez collectionneur, investisseur ou simplement passionné. Planifiez vos sorties et vivez des expériences uniques autour des spiritueux."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de portefeuille : une révolution pour collectionneurs et investisseurs"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les plus chevronnés, The Exchange Platform propose un outil de gestion de portefeuille inédit. Cette fonctionnalité vous permet de monitorer vos positions et leur valeur en temps réel grâce aux prix live issus de la plateforme, combinés à nos données historiques détaillées. Fini la sempiternelle question de la valeur de votre collection ! Avec une vue objective et agnostique sans précédent, vous pouvez suivre l’évolution de vos actifs et prendre des décisions éclairées. Qu’il s’agisse d’évaluer votre collection ou de planifier des transactions stratégiques, l’outil de gestion de portefeuille de TEP transforme votre expérience en offrant une transparence totale et un contrôle optimal de vos spiritueux."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Historiques : une transparence inégalée"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chez TEP, nous avons rassemblé l'historique de prix le plus complet qui soit sur tous les spiritueux possibles, avec des données remontant jusqu'à 2002 ! Grâce à l’agrégation de 70 sources différentes, complétées par nos propres transactions, nous offrons une vue transparente et agnostique des prix passés réalisés. L’ensemble de ces données est soigneusement standardisé et mis à disposition dans 18 devises différentes, permettant à nos utilisateurs de comparer et analyser les tendances du marché à l’échelle mondiale. Cette fonctionnalité unique est un outil indispensable pour les collectionneurs, investisseurs et professionnels souhaitant prendre des décisions éclairées, qu’il s’agisse d’acheter, vendre ou simplement évaluer leur portefeuille de spiritueux."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une garantie de confiance"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les paiements s'effectuent via notre prestataire en service de paiements Mangopay afin d'assurer le bon déroulement des transactions, aucun risque que l'argent disparaisse vous ne l'enverrez jamais à la contrepartie."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais les plus bas du marché"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous appliquons les frais les plus bas du marché, 2.5% TTC pour acheteur et vendeur, aucun frais de listing ou de réserve. Vous ne paierez que s'il y a transaction effective."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymat pré-transactionnel"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que vous soyez un particulier ou un professionnel, notre plateforme est la solution à tout les problèmes actuels grâce à son anonymat pre-transactionnel."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grâce à un contrôle des inscriptions par notre prestataire en service de paiements en amont de la validation de compte, aucun compte fantôme, scammer ou bot ne peut s'introduire sur la plateforme. La traçabilité et la sécurité y sont totales."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une offre adaptée pour votre usage"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez une communauté pour trouver, gérer, échanger vos spiritueux !"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de spiritueux"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteille"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, nom, EAN, mots clefs..."])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher au sein de"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
    }
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog & Review"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evènements"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évènement à venir"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évènements à venir"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier par odre alphabétique"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dates"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de spiritueux"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les évenements"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["du"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au"])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser les filtres"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publié le :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifié le :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les articles"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun article trouvé."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant disponible"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effectuer le retrait"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mouvements"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement effectué"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement reçu"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction à afficher."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert entrant"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert sortant"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement vendeur"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait vers mon compte bancaire"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvisionnement du wallet"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retrait des fonds disponibles"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le retrait"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le virement vers votre compte bancaire a bien été effectué"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le virement vers votre compte a échoué. Si le problème persiste, contactez le service client"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon wallet (0%)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant restant après la transaction :"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accèder à l'historique de mes transactions"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement s'effectue en virement instantanée par défaut"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant disponible au retrait : "])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte bancaire terminant par : "])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transférez de l'argent directement sur votre wallet pour éviter les frais sur les moyens de paiements"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétaire :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos fonds seront disponibles sous 2 à 3 jours ouvrés"])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Element copié avec succès"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier"])}
  },
  "subscriptions": {
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif du paiement"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission d'achat"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA Commission d'achat"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements annuels"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs annuel"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tarifs mensuel"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mois offert sur un paiement annuel "])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre particulier"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre professionnel"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium prix historiques"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les utilisateurs qui souhaitent des historiques complets sans différé de temps."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTC"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par mois "])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par an "])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par type de spiritueux"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès à l'ensemble des références"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de prix sans différé de temps "])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques avancées"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'abonner à un spiritueux"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de spiritueux au choix :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vins"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Module complémentaire"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour les collectionneurs chevronnés qui souhaitent obtenir davantage de données et un réel outil de pilotage."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejoignez une communauté de spécialistes pour analyser, gérer et échanger vos spiritueux !"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion avancée du Portfefeuille"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisir cette offre"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Panier"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement mensuel"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement annuel"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain prélèvement"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA (20%)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procéder au paiement"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet abonnement est déjà dans votre panier"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous êtes déjà abonné"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonné"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour aux abonnements"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations contact"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions au sein de The Exchange Platform sont effectuées via notre prestataire de paiement Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte bancaire"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de carte"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom sur la carte de paiement"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration (MM/YY)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de sécurité"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finaliser l'achat"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bientôt disponible"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le code de vérification de la carte (au dos de la carte, généralement 3 chiffres"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annulation a bien été prise en compte"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'annulation a échouée, veuillez contacter le service client"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bienvenue sur The Exchange Platform"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à un espace de transaction continu, sécurisé et standardisé."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder mon identifiant"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe doit contenir 7 caractères avec au moins une majuscule"])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir correctement tous les champs"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un code valide"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez un email contenant les instructions pour réinitialiser votre mot de passe."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer votre email"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevrez un email contenant les instructions pour réinitialiser votre mot de passe."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation du mot de passe"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de vérification"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentification"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez entrer le code reçu par email ou téléphone"])}
    }
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un compte"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de naissance"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro, rue"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone mobile"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les "])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions d'utilisation "])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte les "])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["conditions d'utilisation "])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["et la "])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité "])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de The Exchange Platform."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire à la newsletter"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre email"])},
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["J'accepte de recevoir des messages de The Exchange Platform au numéro de téléphone indiqué ci-dessus. Des frais de données peuvent s'appliquer, répondez STOP pour vous désinscrire."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En refusant les SMS 2FA, vous ne pourrez pas confirmer votre compte. Vous pouvez toujours vous désinscrire après confirmation de votre numéro de téléphone via notre système de SMS 2FA."])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer mon compte gratuit et continuer"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminer mon inscription"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez un compte vérifié"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entreprise"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse complète"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postale"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email professionnel"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'entreprise"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de SIRET"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° d'entreprise"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de TVA"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon entreprise"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées bancaires"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données bancaires"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accédez à un espace de transaction continu, sécurisé et standardisé."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez votre usage"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le type de compte ne pourra pas être changé une fois l'incription finalisée"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulier"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnel"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte à usage personnel"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte professionnel vérifié"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir tous les champs ci-dessous"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Particulier"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte Entreprise"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie professionnelle"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forme juridique"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaires effectifs détenant plus de 25% ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez renseignez les autres bénéficiaires effectifs détenant plus de 25%"])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["né(e) le "])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réside en "])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" et de nationalité"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir au moins 8 caractères dont une majuscule, une minuscule, un chiffre et un caractère spécial."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation de votre email et téléphone"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code reçu par email"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code reçu par téléphone"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un e-mail vous a été envoyé."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un SMS vous a été envoyé."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu le code ?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoyer"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmé"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de confirmation"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir un code valide"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations, vous avez d'ores et déjà accès à de nombreuses fonctionnalités du site gratuitement !"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Souhaitez-vous faire vérifier votre identité maintenant pour profiter de l'expérience complète ?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir un compte vérifié"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenir un compte vérifié gratuitement"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de votre identité"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le type de document"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte d'identité"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer une photo de votre passeport"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer une photo recto et verso de votre carte d'identité"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un fichier"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier clair et lisible"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille du fichier max. 7 Mo"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format JPG, JPEG ou PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre pour vérification "])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps moyen de vérification est d'environ 24 heures."])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le temps moyen de vérification est de quelques minutes"])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités limitées"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminez l'inscription et accédez à l'ensemble des fonctionnalités."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents envoyés"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de vérification"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez rechercher des bouteilles et consulter les historiques des prix."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas encore accéder à la partie transactionnelle ni souscrire à des abonnements."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé le"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extrait Kbis ou document équivalent de moins de trois mois"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copie des statuts"])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration des bénéficiaires effectifs"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport ou carte d'identité recto/verso"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document envoyé:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents envoyés:"])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez ajouter 2 justificatifs pour la pièce d'identité"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez ajouter un justificatif pour le Passeport"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nom de fichier ou taille maximale de 7Mo par document est dépassée"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusé"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison du refus:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité personnelle"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité de l'entreprise"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statuts"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclaration d'actionnaire"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification de l'adresse"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procèdez au paiement"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° de carte invalide"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom et prénom sur la carte invalide"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration invalide"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de carte bancaire invalide"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de KYC pour les entreprises"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les documents on été envoyés avec succès. La validation sera effectuée sous 24 heures."])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prenez des photos claires et nettes : "])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous que la carte d'identité ou le passeport soit entièrement visible, sans ombre ni reflet, et que tous les détails soient lisibles."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez dans un format compatible :"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez un format JPEG, JPG ou PNG, avec une résolution suffisante pour que les informations soient clairement visibles."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez l'intégralité du document :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous que toutes les pages nécessaires sont incluses distinctement."])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées bancaires"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez vos informations bancaires pour recevoir l'argent de vos ventes."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos données seront soumises à validation par notre service client. Vous recevrez un mail lorsque vos données bancaires seront validées."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, Numéro de routage..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez remplir"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les transactions au sein de The Exchange Platform sont effectuées via notre prestataire de paiement Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations bancaires ont été enregistrées"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos informations bancaires ont déja été envoyées"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Félicitations ! Votre compte est maintenant vérifié."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parcourir les bouteilles"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accéder à mon portfolio"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode illimité actif"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez maintenant accéder à l’ensemble des fonctionnalités."])}
    }
  },
  "search": {
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres en cours"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Page embouteilleur"])},
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scanner ou taper le code barre"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherches récentes"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout supprimer"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit affiché"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produits affichés"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit trouvé avec cette recherche"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produits trouvés avec cette recherche"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produit trouvé"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["produits trouvés"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteille manquante ?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher l'ensemble"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez utiliser des combinaisons de mots-clés."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La recherche n'est pas sensible à la casse."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il est inutile de préciser “ans”."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecrire une catégorie générique de spiritueux (rhum, whisky, cognac...) ne donnera aucun résultat."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier que vous avez bien au moins un mot clef dans la barre de recherche."])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sell"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giver"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block trade"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune bouteille correspondant à votre recherche n'a été trouvée."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir l'historique des recherches"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez un compte pour débloquer un accès sans limitation de résultat"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire gratuitement"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connexion requise pour cette fonctionnalité"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteille manquante"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la bouteille"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si nécessaire, précisez les données de la bouteille manquante"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez décrire là où les raisons du refus"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facultatif"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé."])}
    }
  },
  "orders": {
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite obtenir une"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je possède une"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en échange de"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Que je souhaite échanger contre"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres en cours"])},
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'ordre d'achat"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'ordre de vente"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le Block Trade"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier l'ordre Swap"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les ordres supprimés"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre modifié avec succès"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre créé avec succès"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'ordres :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre, prix..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison du refus"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres avancés"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 filtres sont nécessaires pour les recherches sans mots-clefs"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune bouteille trouvée pour cette référence."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au portfolio"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Buy/Sell"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap"])},
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de livraison"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations bouteille"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composite"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de spiritueux"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Embouteilleur"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Année d'embouteillage"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenance"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de bouteille"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de bouteilles produites"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de sortie"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de sortie"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de production"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de la distillerie"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas d'ordre pour cette bouteille"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker composite"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher et ajouter une bouteille au graphique"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker ou nom du produit"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bouteille que vous recherchez n'a pas été trouvée"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sources"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune image disponible pour cette référence"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de fût"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher l'historique"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live orders"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre bouteilles"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a bien été prise en compte"])},
    "orders": {
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien Blocktrade"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier le lien pour partager votre ordre Blocktrade"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre blocktrade est disponible pour une durée de 2 heures maximum. Pour partager cet ordre de vente privé, copiez le lien en cliquant sur le bouton ci-dessus."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le lien du Blocktrade a bien été copié"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lien unique à partager"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction sécurisée avec notre prestataire de paiement Mangopay"])},
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer ordre d'achat"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer ordre de vente"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer ordre Swap Taker"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer ordre Swap Giver"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un Block Trade"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité de poids"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unité de taille"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un ordre"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer alerte"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez un mail quand un ordre de vente est créé."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez un mail quand un ordre d'achat est créé."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous recevez un mail quand un ordre est créé."])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez vérifier votre saisie"])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour créer une alerte, rendez-vous sur la page produit de votre choix."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix (facultatif)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre alerte a bien été créée"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la création de votre alerte"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre prix doit être compris entre 50 et 30000 EUR ou être à 0 EUR"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre cette notification en pause"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réactiver cette alerte"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuel"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun ordre pour le moment."])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun ordre d'achat pour le moment."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun ordre de vente pour le moment."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre d'achat"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix d'achat"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmer une date et heure de fin ?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer l'ordre d'achat"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre de vente"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire (50-30000)"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimensions du colis"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une bouteille"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter photos"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmer une date et heure de fin ?"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas ajouter 2 fois la même référence"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la quantité maximum autorisé pour une seule vente"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer l'ordre de vente"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre créé avec succès"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande a été prise en compte. Vous serez bientôt recontacté"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règles à respecter en tant que "])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimé"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En vérification"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attente"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédition"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expédier"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours de livraison"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponible en point relais"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En transit"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livré"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litige"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de livraison"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de suivi"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre mon colis"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir le détail de la livraison"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger le bon de livraison"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transaction anonyme (3 EUR TTC)"])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'acheteur ne connaitra pas votre identité"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez accepter ou refuser vos propositions avant de créer un nouvel ordre"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ramassage à domicile"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre colis est retiré à votre domicile par UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'enlèvement"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Première heure d'enlèvement"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernière heure d'enlèvement"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez mettre des images de la bouteille"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous ne pouvez pas mettre plusieurs fois la même image"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seules les images inférieur à 7mb et avec l'extention .JPG, .JPEG et .png sont acceptées"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter des images"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou glissez-déposez"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".JPG, .PNG et .JPEG uniquement (7MB max par photo)"])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre ordre ne peut pas être valide moins d'une heure"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cet ordre ne correspond pas à vos préférences pays ou celles de la contrepartie"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez vérifier votre identité pour accéder à la partie transactionnelle"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifier mon identité"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez d'inonder le carnet d'ordres inutilement"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limitez vos ordres à des transactions réelles."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les refus répétitifs"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les refus à répétition d'un match peuvent être perçus comme malintentionnés ou irresponsables. Assurez-vous de n'engager des transactions que si vous avez l'intention de les finaliser."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respectez les délais de récupération"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une inaptitude répétée à récupérer les colis dans les délais peut entraîner des désagréments pour le vendeur et des coûts supplémentaires."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respectez l'intégrité du carnet d'ordres"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Truquer ou manipuler le carnet d'ordres, désactiver un intérêt après un rejet de match, etc., sont considérés comme des pratiques trompeuses. Veillez à la transparence de vos actions."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soyez prêt à assumer les coûts associés"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Des taxes locales d'importation et de douane peuvent s'appliquer. Assurez-vous d'être prêt à les payer lors de la conclusion d'une transaction."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournissez des informations exactes"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurez-vous que la description de la bouteille est précise et véridique. Cela aide les acheteurs à prendre une décision éclairée. Fournissez une photo intégrale de face et de dos."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respectez les détails de l'offre"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous vous engagez à vendre une bouteille, honorez cet engagement. Toute offre non honorée aura des conséquences pour le vendeur."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantissez la qualité de l'emballage et son contenu"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les réceptions répétées de colis endommagés (détérioration, casse, fuite), mauvais contenu, vous impactent. Assurez-vous d'emballer soigneusement les bouteilles."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Respectez les délais d'expédition"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une inaptitude répétée à expédier dans les délais impartis peut affecter votre accès à l'espace transactionnel. Planifiez en conséquence pour éviter les retards."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence du colis:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez détailler votre problème ici pour faiciliter la résolution du ticket"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la bonne réception du colis"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En confirmant la bonne réception du colis, vous confirmer que le produit est conforme à vos attentes."])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de prix"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une bouteille"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les graphiques sont à titre informatif. Nous déclinons toute responsabilité pour les éventuelles erreurs."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nos données historiques agrègent"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ventes "])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vente "])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["provenant de"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sources différentes."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["source."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haut"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique ligne"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graphique bougie"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options du graphique"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Performance"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["depuis"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les prix sont affichés avec un différé de"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de données de prix historiques disponibles. Si nous ne les avons pas encore intégrées, n'hésitez pas à contacter le support pour les faire ajouter."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vue actuelle"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journalière"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuelle"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuelle"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hebdomadaire"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comparer avec"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune bouteille séléctionnée"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette bouteille est déjà présente sur le graphique"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez atteint la limite de 6 bouteilles pour le graphique"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher le Composite"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signaler une erreur"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'erreur"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remarques"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facultatif"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez plus précisément le problème ici..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captures d'écran / Images"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["2 images max."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])}
    }
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matchs"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match acheteur"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Match vendeur"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter et vendre"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Afficher mes ", _interpolate(_named("num_transac")), " transactions en cours"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les photos"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon ordre d'achat"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre d'achat"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrepartie"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre de vente"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon ordre de vente"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheteur"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun match à afficher."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de compte et fonctionnalités offertes pour tous"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonctionnalités"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte non vérifié"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte vérifié"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accès complet gratuit et illimité"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique de prix"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualisation avancée"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15 mois de différé"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 mois de différé"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portefeuille"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisation différée de 15 mois"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisation différée de 12 mois"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers uniques"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 tickers uniques"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de données"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tickers par jour"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trading"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualisation des ordres actifs"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun accès transactionnel"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification ID gratuite pour les particuliers et payante pour les professionnels. KYC effectuée par notre prestataire en service de paiements Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited full access"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID copié"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Import d’un fichier CSV ou Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteille"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout désélectionner"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher une bouteille"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous guider,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargez"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger un fichier"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou glissez-déposez"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichiers .CSV et .XLSX uniquement"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un modèle d'import"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonnes"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moyenne de détention"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer dans un autre dossier"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renommer le dossier"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nom du dossier est requis"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le nombre maximal de caractères ne doit pas dépasser 30 caractères"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statistiques"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonnes tableau"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options tableau"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout replier"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout déplier"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les stocks vendus"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickers"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Infos"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix net"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix brut"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de stockage"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détention"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'achat"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'un dossier"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une bouteille"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du dossier parent"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonnes supplémentaires"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre des colonnes"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes bouteilles"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'achat"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter les options de prix"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de vente"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'échange"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achat"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune Bouteille correspondant à votre recherche n'a été trouvée."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez le dossier parent"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier créé"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dossier a été modifié"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du dossier"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le dossier a été supprimé"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur lors de la création du dossier"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un dossier"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la bouteille"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En cours"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles détenues"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles en votre possession"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bouteille a été ajoutée"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bouteille a été modifiée"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La bouteille a été déplacée"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichié importé avec succès"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références unique"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles détenues multiplié par le prix d'achat respective"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles détenues multiplié par la valeur de marché"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus value en cours, différence entre valeur de marché et prix d'achat multiplié par quantité"])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement en cours"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement annuel en cours"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix moyen de toutes les bouteilles"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée moyenne durant laquelle une Pétenue par l’acheteur est retenue dans le portefeuille"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos bouteilles achetées jusqu'à maintenant"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos bouteilles vendues jusqu'à maintenant"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des dépenses réalisées jusqu'à maintenant"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des ventes réalisées jusqu'à maintenant"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain accumulé depuis le premier jour jusqu'à aujourd'hui"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain réalisé sur la période sélectionnée"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement calculé depuis le premier jour (uniquement prendre en compte les bouteilles vendues)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement effectif annuel (bouteilles vendues)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références uniques"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur Initiale Position"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement actuel"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement annualisé"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement annuel historique réalisé"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement réalisé historique"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix moyen"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles achetées"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker unique"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valorisation"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain latent"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles vendues"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des dépenses"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain total réalisé"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gain réalisé (période)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rendement historique réalisé"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total des ventes"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'achat/vente"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix net"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix hors frais"])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix avec frais"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix avec TVA"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix net"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix d'achat brut"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix de vente brut"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de stockage"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la référence"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer ce dossier ?"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suppression impossible car il y a des bouteilles dans ce dossier"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fichier excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseils porur votre fichier excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colonnes affichées"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout sélectionner"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espace utilisé"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un dossier"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une bouteille"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer un fichier"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteille actuelle"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bouteilles actuelles"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence actuelle"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Références actuelles"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier actuel"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer vers un dossier"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier d'origine :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous allez déplacer ce dossier ainsi que toutes les bouteilles présentes dans celui-ci."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre dossier a été déplacé"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bouteilles dans le dossier"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["références dans le dossier"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer vers un dossier"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détention (jour)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pièces jointes"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu d'achat"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de vente"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité vendue"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe d'actifs"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aller à la fiche produit"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier prix"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilité"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout déselectionner"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bouteille(s) sélectionnée(s)"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déplacer"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer la bouteille"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le portfolio est actuellement accessible uniquement sur PC, mais il le sera bientôt sur mobile."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilité du portefeuille"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilité type de spiritueux"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilité distillerie"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilité vintage"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilité age"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio premium bientôt disponible"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir les détails"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Go to product page"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer une offre"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seul les fichiers .jpg, .jpeg et .png de moins de 3Mb sont autorisés"])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de la transaction"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter au panier"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer du panier"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif de l'ordre"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais d'expédition"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dont TVA à"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendre"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de transaction"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais moyen paiement"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS à domicile"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS en point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Points de retraits disponibles"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder cette adresse pour mes prochains achats"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transaction a échouée. Veuillez ré-essayer"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La transaction a été finalisée avec succès"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre paiement est en cours de vérification"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de patienter quelques secondes"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètre compte"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion abonnements"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes abonnements"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de paiement"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des factures"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon compte"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Achats & ventes"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées bancaires"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité et 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civilité"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville de naissance"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de naissance"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Point relais UPS"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une autre adresse de livraison"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer l'adresse de livraison"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non renseigné"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titulaire du compte"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyé le "])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer de RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentification"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevez un code à chaque connexion et à chaque fois qu'une modification est effectuée sur votre compte"])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentification par email"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Double authentification par SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernières connexions"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visualisez l'historique des 10 dernières connexions à votre compte sur l'ensemble des appareils."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recevoir la newsletter"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte de débit n°"])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutée le"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expire le"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier ma carte bancaire"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas encore renseigné de carte de paiement."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre cette adresse par défaut"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison préférée"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos préférences ont été enregistrées avec succès"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un ordre d'achat"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un ordre de vente"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un ordre swap"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création d'un ordre blocktrade"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un acheteur a refusé le match"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre de vente en attente de validation"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autoriser la réception de SMS (validation du compte et/ou 2FA)"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement adresse e-mail"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email actuelle"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvelle adresse email"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement numéro de téléphone"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone actuel"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau numéro de téléphone"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout d'une adresse de livraison"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email incorrect"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone incorrect"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà cet email"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà ce numéro de téléphone"])}
    },
    "subscriptions": {
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium prix historiques"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mois"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépenses du mois en cours"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonné depuis le"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'êtes pas abonné"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnements annuels"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain prélèvement le"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonné jusqu'au"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l’abonnement"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Explorer les abonnements de prix historiques disponibles"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler un abonnement"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre abonnement prendra fin le"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement concerné"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer l'annulation"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reprendre l'abonnement"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer un abonnement"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prochain paiement aura lieu le"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement concerné"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le paiement sera effectué tous les"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au prix de"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer la reprise"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagé jusqu'au"])}
    },
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options d'achats"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options de ventes"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité d'achat"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visibilité de vente"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays à exclure"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays à inclure"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copier mes préférences d'achat pour les ventes"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vos changements on été pris en compte"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des factures"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout télécharger"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abonnement"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger la facture"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payé"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas de facture"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails livraison"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivre le colis"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison estimée"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° transaction"])}
    },
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préférences de communication"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code reçu par e-mail"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez saisir le code reçu par téléphone"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouvel e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau téléphone"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le code reçu par e-mail"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez le code reçu par téléphone"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de facturation et de livraison"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litige en cours"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déclarer un litige"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune transaction réalisée."])}
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseaux sociaux"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Légal"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre d'aide"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrutement"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rapporter un bug"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut des services"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mentions légales"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CGU & CGV"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des cookies"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["entreprise immatriculée en France"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxes"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acheter"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refuser"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif de l'ordre"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendu part :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de vente"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])}
  }
}
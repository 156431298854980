<template>
  <form class="newsletter" :class="{ column: !row }">
    <input
      type="email"
      name="newsletter"
      :placeholder="$t('register.newsletter_enter_email')"
      effect="dark"
      v-model="email"
    />
    <button
      v-if="!isSubsribe"
      @click.prevent="subscribe()"
      class="generic-btn sub-btn"
    >
      {{ $t('register.newsletter_register') }}
    </button>
    <button v-else @click.prevent="subscribe()" class="generic-btn sub">
      {{ $t('register.newsletter_register') }}
      <img
        src="@/assets/svg/v2/accountVerificationInformations/kyc_account.svg"
        alt="Newsletter OK"
      />
    </button>
  </form>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'newsletter',
  props: {
    row: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      email: '',
      isSubsribe: false,
    }
  },
  methods: {
    ...mapActions({
      newsletters: 'newsletter',
    }),
    async subscribe() {
      if (!this.isSubsribe) {
        const payload = {
          email: this.email,
        }

        await this.newsletters(payload).then(() => {
          this.isSubsribe = true
          this.email = ''
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
@import '@/assets/styles/root.scss';

.newsletter {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;

  input {
    color: white;
    width: 220px;
    height: 42px;
    outline: none;
    background-color: #333;
    border: 0;
    border-radius: 20px;
    color: white;
    padding-left: 10px;
    margin-right: 15px;
    font-size: 15px;
    text-align: center;
  }

  button {
    width: 230px;
  }

  .sub-btn {
    font-size: 15px;
  }

  .sub {
    padding: 11px 20px;
  }
}

.column {
  flex-direction: column;
  justify-content: flex-start;

  input {
    margin-right: 0;
  }

  button {
    margin-top: 10px;
  }
}

@media screen and (max-width: 500px) {
  .newsletter {
    flex-direction: column;

    input {
      margin-right: 0;
      margin-bottom: 10px;
    }

    button {
      margin-top: 0;
    }
  }
}
</style>

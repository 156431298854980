export default {
  "commons": {
    "refuse_match_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmietnuť zhodu"])},
    "date_expire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum exspirácie"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup a predaj"])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmena"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blocktrade"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólio"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "bid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponuka"])},
    "ask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spýtajte sa"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darca"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "report_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť chybu"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ účtu"])},
    "orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávky"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne objednávky"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množstvo"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť na"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatvoriť"])},
    "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť"])},
    "apply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia"])},
    "product_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pôvod produktu"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať na"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojenie"])},
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásiť sa"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť účet"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávanie a obchodovanie"])},
    "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predplatné"])},
    "price_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena je vrátane DPH"])},
    "dark_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nočný režim"])},
    "light_mode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Režim Clear"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["História transakcií"])},
    "portfolio_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólio"])},
    "matchs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zápasy"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["História transakcií"])},
    "account_settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia účtu"])},
    "enabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivované"])},
    "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivované"])},
    "alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámenia"])},
    "alerts_clear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať všetky"])},
    "alerts_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentálne žiadne oznámenia"])},
    "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
    "no_copy_paste": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemôžete kliknúť pravým tlačidlom myši"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodávka"])},
    "secure3d": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola transakcie"])},
    "cancel_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušiť"])},
    "vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPH"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkom"])},
    "too_many_request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadávate príliš veľa požiadaviek"])},
    "see_products_limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosiahli ste svoj denný limit konzultácií. Skontrolujte si svoj účet, aby ste sa vyhli ďalším obmedzeniam."])},
    "check_inputs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte si svoj vstup:"])},
    "generic_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba, skúste to neskôr"])},
    "login_retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihláste sa znova"])},
    "email_phone_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pred vykonaním tejto akcie overte svoj e-mail a telefón"])},
    "not_authorized": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ste oprávnení vykonávať túto činnosť"])},
    "service_unaivalable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Služba nie je k dispozícii, kontaktujte zákaznícky servis"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informačný kanál"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívne"])},
    "inputs_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte, či sú všetky požadované polia vyplnené správne"])},
    "lang": {
      "lang_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si jazyk"])},
      "fr": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FR"])},
      "fr_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Francúzska"])},
      "en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "en_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angličtina"])},
      "es": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ES"])},
      "es_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Španielska"])},
      "it": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IT"])},
      "it_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Talianska"])},
      "pl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PL"])},
      "pl_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poľská stránka"])},
      "de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DE"])},
      "de_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemecká stránka"])},
      "cs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CS"])},
      "cs_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Česká"])},
      "da": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DA"])},
      "da_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dánska"])},
      "el": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL"])},
      "el_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grécka"])},
      "hu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HU"])},
      "hu_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maďarská stránka"])},
      "ja": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JA"])},
      "ja_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japonská stránka"])},
      "ko": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KO"])},
      "ko_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kórejská stránka"])},
      "pt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PT"])},
      "pt_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugalská stránka"])},
      "ru": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UK"])},
      "ru_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ruská stránka"])},
      "sk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SK"])},
      "sk_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slovenská"])},
      "zh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZH"])},
      "zh_label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čínska stránka"])}
    },
    "report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť problém"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujúci"])},
    "seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predávajúci"])},
    "code_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaslaný kód"])},
    "code_time_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozorňujeme, že kód je platný 15 minút"])},
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Články"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionálna stránka"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuálne"])},
    "wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peňaženka"])},
    "my_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Peňaženka"])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všetky stránky"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])},
    "error_submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte svoj vstup alebo kontaktujte zákaznícky servis"])},
    "copy_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovať"])},
    "copy_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešná kópia"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter :"])},
    "orders_alerts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upozornenia"])},
    "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min:"])},
    "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max:"])},
    "error_503": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akcia bola zrušená, pretože bola prekročená čakacia doba. Skúste to prosím znova"])}
  },
  "major": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na návštevu tejto stránky musíte mať viac ako 18 rokov"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri vstupe si skontrolujte svoj vek."])},
    "ok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mám viac ako 18 rokov"])},
    "nok": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Som mladší ako 18 rokov"])},
    "conditions_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístupom na túto stránku vyjadrujete súhlas s"])},
    "conditions_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podmienky používania"])},
    "conditions_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a ."])},
    "conditions_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásady ochrany osobných údajov."])}
  },
  "home": {
    "revolution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revolúcia sama o sebe"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referencia na nákup, predaj a obchodovanie s liehovinami"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Či už ste milovník whisky, zberateľ koňaku alebo profesionál, ktorý hľadá dokonalosť, platforma The Exchange Platform je určená práve vám. Optimálna bezpečnosť, úplná transparentnosť a nízke poplatky: objavte obchodovanie s liehovinami s úplným pokojom."])},
    "secure_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečné transakcie"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znížené poplatky 2,5 % vrátane DPH"])},
    "verified_accounts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 % overené bezplatné účty"])},
    "title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Či už ste jednotlivec alebo profesionál, preskúmajte náš súhrn viac ako 70 zdrojov cien tisícov referenčných liehovín!"])},
    "create_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorte si bezplatné konto bez predplatného"])},
    "buy_and_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup a predaj: vaše transakcie sú bezpečné a transparentné"])},
    "buy_and_sell_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Burzová platforma uľahčuje nepretržitý nákup a predaj. Získajte jednoduchý prístup ku všetkým dostupným ponukám vďaka našim štandardizovaným referenciám, ktoré uľahčujú porovnávanie a zaručujú transparentnosť. Nájdite to, čo hľadáte, alebo vyjadrite svoj záujem: TEP vám umožňuje zosúladiť vaše potreby s vyhľadávaním protistrán, či už kupujete alebo predávate. Ušetrite čas, optimalizujte svoje transakcie a obchodujte vždy za najlepšiu cenu. Vďaka intuitívnemu rozhraniu a bezpečným transakciám TEP zjednodušuje obchodovanie so špiritusom a ponúka rýchle, spoľahlivé a efektívne služby pre amatérov aj profesionálov."])},
    "swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmena fliaš: bezpečná a zaručená bez rizika protistrany"])},
    "swap_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TEP je jediná platforma, ktorá ponúka možnosť výmeny fliaš v úplnom bezpečí vďaka našim riešeniam zabezpečenia hotovosti alebo skladového depozitu. Tento systém zaručuje úplnú ochranu: neexistuje žiadne riziko protistrany, pretože peniaze sú zabezpečené u nášho poskytovateľa platobných služieb až do potvrdenia príslušných dodávok fliaš. Táto funkcia spôsobuje revolúciu na trhu s liehovinami tým, že poskytuje bezprecedentnú transparentnosť a spoľahlivosť. Či už ste zberateľ alebo znalec, Exchange vám umožní rozšíriť alebo optimalizovať vašu zbierku bez akýchkoľvek obáv o bezpečnosť transakcií. Vymieňajte svoje liehoviny s úplným pokojom v modernom a bezpečnom prostredí."])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokové obchodovanie: Jednoduché zabezpečenie externých transakcií"])},
    "blocktrade_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Block Trade, exkluzívna funkcia TEP, umožňuje zabezpečiť transakcie nákupu/predaja len niekoľkými kliknutiami. Vďaka tejto inovácii sa pre každú transakciu generuje jedinečný odkaz, ktorý zaručuje spoľahlivé a bezpečné vykonanie. Či už nakupujete alebo predávate, Block Trade eliminuje riziká spojené s priamymi externými burzami tým, že poskytuje transparentný a bezpečný rámec. Obsahuje tiež pokročilé nástroje na potvrdenie podmienok transakcie, overenie účtov a zabezpečenie bezproblémovej realizácie v každej fáze. Objavte nový spôsob obchodovania s liehovinami s pokojom a spoľahlivosťou, ktoré ponúka TEP."])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa portfólia: revolúcia pre zberateľov a investorov"])},
    "portfolio_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre skúsenejších ponúka burzová platforma jedinečný nástroj na správu portfólia. Táto funkcia umožňuje sledovať pozície a ich hodnotu v reálnom čase pomocou živých cien z platformy v kombinácii s našimi podrobnými historickými údajmi. Už žiadne nekonečné otázky o hodnote vašej zbierky! Vďaka bezprecedentne objektívnemu a agnostickému pohľadu môžete sledovať vývoj svojich aktív a prijímať informované rozhodnutia. Či už hodnotíte svoju zbierku, alebo plánujete strategické transakcie, nástroj na správu portfólia TEP mení vaše skúsenosti tým, že poskytuje úplnú transparentnosť a optimálnu kontrolu nad vašimi duchmi."])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny: bezkonkurenčná transparentnosť"])},
    "price_history_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na TEP sme zostavili najkomplexnejšiu históriu cien všetkých možných liehovín, pričom údaje siahajú až do roku 2002! Vďaka agregácii 70 rôznych zdrojov, doplnených o naše vlastné transakcie, ponúkame transparentný a agnostický pohľad na realizované ceny v minulosti. Všetky tieto údaje sú starostlivo štandardizované a sprístupnené v 18 rôznych menách, čo našim používateľom umožňuje porovnávať a analyzovať trhové trendy v celosvetovom meradle. Táto jedinečná funkcia je nepostrádateľným nástrojom pre zberateľov, investorov a profesionálov, ktorí chcú prijímať informované rozhodnutia, či už ide o nákup, predaj alebo len o hodnotenie ich portfólia liehovín."])},
    "title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záruka dôvery"])},
    "secure_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platby sa uskutočňujú prostredníctvom nášho poskytovateľa platobných služieb Mangopay, aby sa zabezpečil hladký priebeh transakcií, žiadne riziko, že peniaze zmiznú, nikdy ich protistrane nepošlete."])},
    "low_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnižšie poplatky na trhu"])},
    "low_fees_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máme najnižšie poplatky na trhu, 2,5 % s DPH pre kupujúceho a predávajúceho, žiadne poplatky za zaradenie do zoznamu ani za rezerváciu. Platíte len vtedy, ak dôjde k efektívnej transakcii."])},
    "anonymous_transactions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymita pred transakciou"])},
    "anonymous_transactions_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Či už ste jednotlivec alebo profesionál, naša platforma je vďaka svojej anonymite pred transakciami riešením všetkých dnešných problémov."])},
    "verified_accounts_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vďaka kontrole registrácie zo strany nášho poskytovateľa platobných služieb pred overením účtu sa do platformy nedostane žiadny fantómový účet, podvodník ani bot. Vysledovateľnosť a bezpečnosť sú úplné."])},
    "title_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ponuka prispôsobená vašim potrebám"])},
    "join_community": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojte sa ku komunite a vyhľadávajte, spravujte a obchodujte s liehovinami!"])},
    "search": {
      "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ liehovín"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
      "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaša"])},
      "search_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker, názov, EAN, kľúčové slová..."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávanie"])},
      "search_bottle_bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávanie v rámci"])}
    },
    "without_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodovanie je k dispozícii bez predplatného!"])},
    "intro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Či už ste amatér, zberateľ alebo profesionál, platforma The Exchange je pre vás ako stvorená: optimálna bezpečnosť, úplná transparentnosť a nízke transakčné náklady so 70 zdrojmi historických cien pokrývajúcimi tisíce referenčných fliaš."])},
    "create_account_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objavte obchodovanie s liehovinami s úplným pokojom."])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Newsfeed: vaše bezplatné centrum správ o liehovinách"])},
    "newsfeed_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prostredníctvom informačného kanála TEP môžete sledovať najnovšie správy z odvetvia, nové vydania, významné udalosti a trendy na trhu. Táto funkcia umožňuje sledovať všetko, čo sa deje vo svete liehovín v reálnom čase, s informáciami z najdôležitejších a najuznávanejších stránok v tomto odvetví. Newsfeed vám poskytuje kompletný, agnostický prehľad o novinkách, takže môžete mať prehľad o vývoji na trhu na jednom mieste."])},
    "blog_and_review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog a recenzie: váš priestor na analýzu a objavovanie"])},
    "blog_and_review_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preskúmajte bezplatný degustačný obsah s blogom a recenziou TEP. Vďaka recenziám od odborníkov a nadšencov z odvetvia vám Blog & Review poskytuje prístup k jedinečným pohľadom a dôveryhodným odporúčaniam. Či už chcete objaviť nové fľaše, pochopiť trendy na trhu alebo rozšíriť svoje vedomosti, tento kanál je navrhnutý tak, aby obohatil vaše zážitky zo sveta liehovín."])},
    "events_browser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehliadač podujatí: váš sprievodca podujatiami zameranými na liehoviny"])},
    "events_browser_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S bezplatným prehliadačom udalostí TEP už nikdy nezmeškáte žiadne dôležité stretnutie vo svete liehovín. Zistite informácie o exkluzívnych veľtrhoch a podujatiach, nech ste kdekoľvek. Vďaka starostlivo aktualizovanému výberu vám Events Browser prináša najdôležitejšie podujatia v tomto odvetví, či už ste zberateľ, investor alebo jednoducho vášnivý nadšenec. Naplánujte si výlety a vychutnajte si jedinečné zážitky spojené s liehovinami."])}
  },
  "newsfeed": {
    "blog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blog a recenzia"])},
    "news": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informačný kanál"])},
    "events": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Udalosti"])},
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť filtre"])},
    "event_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "sort_asc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoradiť podľa abecedy"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátumy"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesiac"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ liehovín"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávať..."])},
    "events_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrovanie udalostí"])},
    "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum začatia"])},
    "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum ukončenia"])},
    "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
    "to": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na adrese"])},
    "events_show_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadchádzajúce podujatia"])},
    "events_show_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nadchádzajúce podujatia"])}
  },
  "subscriptions": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročné predplatné"])},
    "price_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročné kurzy"])},
    "price_m": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesačné sadzby"])},
    "free_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1 mesiac zadarmo pri ročnej platbe"])},
    "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Špeciálna ponuka"])},
    "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionálna ponuka"])},
    "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémiové historické ceny"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre používateľov, ktorí chcú kompletné historické údaje bez časového oneskorenia."])},
    "all_taxs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT"])},
    "per_month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za mesiac"])},
    "per_year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za rok"])},
    "per_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podľa typu liehoviny"])},
    "references_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup ku všetkým referenciám"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny bez časového oneskorenia"])},
    "advanced_stats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilé štatistiky"])},
    "subscribe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa k odberu liehovín"])},
    "choice_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber liehovín :"])},
    "wine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vína"])},
    "others": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšie stránky"])},
    "complementary_module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doplnkový modul"])},
    "portfolio_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre skúsených zberateľov, ktorí chcú viac údajov a skutočný nástroj na správu."])},
    "portfolio_subtitle_pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripojte sa ku komunite odborníkov a analyzujte, spravujte a obchodujte s liehovinami!"])},
    "advanced_gestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilá správa portfólia"])},
    "choice_this_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si túto ponuku"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať do košíka"])},
    "cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Košík"])},
    "see_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť"])},
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesačná platba"])},
    "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročné platby"])},
    "sub_total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drobné"])},
    "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalší odber vzoriek"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkom"])},
    "include_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPH (20 %)"])},
    "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejdite na platbu"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
    "already_in_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto predplatné je už vo vašom košíku"])},
    "already_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Už ste predplatiteľom"])},
    "cart_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš košík je prázdny"])},
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Späť na predplatné"])},
    "contact_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktné informácie"])},
    "billing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účtovanie"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spôsob platby"])},
    "payment_method_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcie na platforme The Exchange Platform sa uskutočňujú prostredníctvom nášho poskytovateľa platobných služieb Mangopay."])},
    "banking_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditná karta"])},
    "card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo karty"])},
    "card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov na platobnej karte"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum exspirácie (MM/RRRR)"])},
    "card_code_security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečnostný kód"])},
    "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončite svoj nákup"])},
    "soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Už čoskoro"])},
    "recap_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad platieb"])},
    "buy_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákupná provízia"])},
    "buy_commission_tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DPH Nákupná provízia"])},
    "already_sub_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predplatiteľ"])}
  },
  "payment": {
    "cvx_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód karty (na zadnej strane karty, spravidla 3 číslice"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušenie bolo zohľadnené"])},
    "cancel_fail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zrušenie sa nepodarilo, kontaktujte zákaznícky servis"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitajte na burzovej platforme"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získajte prístup k bezproblémovému, bezpečnému a štandardizovanému obchodnému prostrediu."])},
    "save_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložiť moje prihlásenie"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásiť sa"])},
    "forgot_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zabudli ste heslo?"])},
    "forgot": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovenie hesla"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostanete e-mail s pokynmi, ako obnoviť heslo."])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte svoj e-mail"])}
    },
    "reset": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovenie hesla"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostanete e-mail s pokynmi, ako obnoviť heslo."])},
      "new_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové heslo"])},
      "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdenie hesla"])},
      "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overovací kód"])}
    },
    "doubleFA": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojité overenie"])},
      "code_reception": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód, ktorý ste dostali e-mailom alebo telefonicky"])}
    },
    "password_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše heslo musí obsahovať 7 znakov s aspoň jedným veľkým písmenom."])},
    "wrong_values": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte správne všetky polia"])},
    "wrong_code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný kód"])}
  },
  "register": {
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť účet"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krstné meno"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum narodenia"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štátna príslušnosť"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "address_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo ulice"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštové smerovacie číslo"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefón"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])},
    "confirm_password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdenie hesla"])},
    "mangopay_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijímam"])},
    "mangopay_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podmienky používania"])},
    "mangopay_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mangopay."])},
    "tep_accept_p1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijímam"])},
    "tep_accept_p2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["podmienky používania"])},
    "tep_accept_p3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a"])},
    "tep_accept_p4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zásady ochrany osobných údajov"])},
    "tep_accept_p5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de Burzová platforma."])},
    "newsletter_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihláste sa na odber noviniek"])},
    "newsletter_enter_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte svoj e-mail"])},
    "create_and_continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorte si bezplatné konto a pokračujte"])},
    "end_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončite svoju registráciu"])},
    "verify_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získajte overené konto"])},
    "company_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov spoločnosti"])},
    "company_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úplná adresa"])},
    "company_zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštový kód"])},
    "company_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "company_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina"])},
    "company_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionálny e-mail"])},
    "company_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ spoločnosti"])},
    "company_siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo spoločnosti"])},
    "company_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo DPH"])},
    "company_name_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moja spoločnosť"])},
    "company_siret_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["123 456 789 00010"])},
    "step": {
      "step_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všeobecné informácie"])},
      "step_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdenie 2FA"])},
      "step_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontrola KYC"])},
      "step_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankové údaje"])},
      "step_1_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie"])},
      "step_4_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankové údaje"])}
    },
    "step_0": {
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získajte prístup k bezproblémovému, bezpečnému a štandardizovanému obchodnému prostrediu."])},
      "usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si spôsob použitia"])},
      "account_no_change": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po dokončení registrácie nie je možné zmeniť typ účtu."])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jednotlivci"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionálna stránka"])},
      "individual_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Účet na osobné použitie"])},
      "professionnal_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overený profesionálny účet"])}
    },
    "step_1": {
      "general_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Všeobecné informácie"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte všetky polia nižšie"])},
      "personnal_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súkromný účet"])},
      "business_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firemný účet"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
      "business_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profesionálna kategória"])},
      "business_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právna forma"])},
      "business_part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výhodní vlastníci, ktorí vlastnia viac ako 25 % ?"])},
      "business_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uveďte podrobnosti o ďalších vlastníkoch požitkov, ktorí vlastnia viac ako 25 %."])},
      "add_person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať"])},
      "birth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Narodila sa na"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobyt v"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" a národnosť"])},
      "password_requirement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo musí obsahovať aspoň 8 znakov vrátane veľkého písmena, malého písmena, číslice a špeciálneho znaku."])}
    },
    "step_2": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdenie e-mailu a telefónneho čísla"])},
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód, ktorý ste dostali e-mailom"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód prijatý telefonicky"])},
      "email_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bol vám zaslaný e-mail."])},
      "phone_customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bola vám zaslaná SMS správa."])},
      "no_receipt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neobdržali ste kód?"])},
      "resend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať na"])},
      "validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdené"])},
      "await_validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čaká sa na potvrdenie"])},
      "code_err": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte platný kód"])}
    },
    "before_kyc": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulujeme, k mnohým funkciám stránky už máte bezplatný prístup!"])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcete si teraz nechať overiť svoju totožnosť, aby ste si mohli vychutnať všetky zážitky?"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získajte overené konto"])},
      "continue_free": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Získajte bezplatné overené konto"])}
    },
    "step_3": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overenie vašej totožnosti"])},
      "document_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si typ dokumentu"])},
      "passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passport"])},
      "identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifikačná karta"])},
      "import_file_passport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať fotografiu z pasu"])},
      "import_file_identity_card": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať fotografiu oboch strán preukazu totožnosti"])},
      "choice_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte súbor"])},
      "condition_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľadný a čitateľný súbor"])},
      "condition_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veľkosť súboru max. 7 Mb"])},
      "condition_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formát JPG, JPEG alebo PNG"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať na overenie"])},
      "verification_timing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priemerný čas overenia je približne 24 hodín."])},
      "active_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obmedzená funkčnosť"])},
      "description_restriction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončite proces registrácie a získajte prístup ku všetkým funkciám."])},
      "documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslané dokumenty"])},
      "verification_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V súčasnosti sa kontroluje"])},
      "verification_in_progress_restriction_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete vyhľadávať fľaše a prezerať si historické ceny."])},
      "verification_in_progress_restriction_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatiaľ nie je možné pristupovať k transakčnej časti ani odoberať predplatné."])},
      "send_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslané na"])},
      "kbis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výpis z Kbis alebo rovnocenný dokument starší ako tri mesiace"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kópia stanov spoločnosti"])},
      "passport_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cestovný pas alebo občiansky preukaz, obe strany"])},
      "document_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslaný dokument: V prípade, že sa na stránke nachádzajú informácie o alkoholických nápojoch, je možné ich zaslať:"])},
      "documents_count": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslané dokumenty:"])},
      "refused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmietnuté"])},
      "refused_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dôvod odmietnutia:"])},
      "IDENTITY_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Osobná identita"])},
      "REGISTRATION_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Firemná identita"])},
      "ARTICLES_OF_ASSOCIATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stanovy"])},
      "SHAREHOLDER_DECLARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhlásenie akcionára"])},
      "ADDRESS_PROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overenie adresy"])},
      "payment_process": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejdite na platbu"])},
      "error_card_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatné číslo karty"])},
      "error_card_fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meno a priezvisko na neplatnej karte"])},
      "error_card_expiration_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatný dátum skončenia platnosti"])},
      "error_card_cvx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neplatný kód kreditnej karty"])},
      "title_payment_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KYC poplatky pre spoločnosti"])},
      "kyc_documents_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokumenty boli úspešne odoslané. Overenie sa uskutoční do 24 hodín."])},
      "ubo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhlásenie vlastníkov požitkov"])},
      "verification_timing_individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priemerný čas overenia je len niekoľko minút"])},
      "kyc_explain_title_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Urobte jasné a ostré fotografie:"])},
      "kycexplain_content_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uistite sa, že občiansky preukaz alebo pas je úplne viditeľný, bez tieňov alebo odrazov, a že všetky údaje sú čitateľné."])},
      "kyc_explain_title_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odosielať môžete v kompatibilnom formáte:"])},
      "kycexplain_content_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Použite formát JPEG, JPG alebo PNG s dostatočným rozlíšením, aby boli informácie dobre viditeľné."])},
      "kyc_explain_title_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozrite si celý dokument :"])},
      "kycexplain_content_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uistite sa, že všetky potrebné stránky sú zahrnuté samostatne."])},
      "documents_min_identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musíte pridať 2 doklady totožnosti"])},
      "documents_min_passeport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je potrebné pridať účtenku pre pas"])},
      "refuse_generic_kyc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov vášho súboru alebo maximálna veľkosť 7 MB na dokument bola prekročená"])}
    },
    "step_4": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankové údaje"])},
      "why_enter_banking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte svoje bankové údaje a získajte peniaze z predaja."])},
      "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše údaje budú predmetom overenia zo strany nášho oddelenia služieb zákazníkom. Po overení vašich bankových údajov dostanete e-mail."])},
      "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
      "iban_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["XX11 0000 0000 0000 0000 000"])},
      "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC/ABA"])},
      "bic_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC, smerovacie číslo..."])},
      "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
      "region_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyplňte prosím"])},
      "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
      "mangopay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcie na platforme The Exchange Platform sa uskutočňujú prostredníctvom nášho poskytovateľa platobných služieb Mangopay."])},
      "banking_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše bankové údaje boli uložené"])},
      "banking_already_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše bankové údaje už boli odoslané"])}
    },
    "success": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gratulujeme! Váš účet je teraz overený."])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prezerajte si fľaše"])},
      "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup k môjmu portfóliu"])},
      "unlock_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neobmedzený aktívny režim"])},
      "unlock_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teraz máte prístup k celej škále funkcií."])}
    },
    "us_phone_regulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súhlasím so zasielaním správ z burzovej platformy na uvedené telefónne číslo. Môžu sa účtovať poplatky za prenos dát, ak sa chcete odhlásiť, odpovedzte STOP."])},
    "us_phone_regulation_warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak odmietnete SMS správy 2FA, nebudete môcť potvrdiť svoj účet. Po potvrdení svojho telefónneho čísla prostredníctvom nášho systému SMS 2FA sa môžete vždy odhlásiť."])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto narodenia"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina narodenia"])},
    "company_siret_FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo SIRET"])}
  },
  "search": {
    "barcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naskenujte alebo zadajte čiarový kód"])},
    "last_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedávne vyhľadávania"])},
    "delete_all_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať všetky"])},
    "find_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazený produkt"])},
    "find_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zobrazené produkty"])},
    "total_product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkt nájdený pomocou tohto vyhľadávania"])},
    "total_products": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produkty nájdené pomocou tohto vyhľadávania"])},
    "total_product_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájdený produkt"])},
    "total_products_short": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nájdené produkty"])},
    "empty_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chýba vám fľaša?"])},
    "filter_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrovanie podľa"])},
    "see_composite_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť všetky"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpiť"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taker"])},
    "giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Darca"])},
    "blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokové obchodovanie"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "no_result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašli sa žiadne fľaše zodpovedajúce vášmu vyhľadávaniu."])},
    "history_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozrite si históriu vyhľadávania"])},
    "no_auth_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorte si účet a odomknite neobmedzený prístup"])},
    "no_auth_register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrujte sa bezplatne"])},
    "connect_access_search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre túto funkciu sa vyžaduje prihlásenie"])},
    "empty": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chýbajúca fľaša"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov fľaše"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V prípade potreby uveďte podrobnosti o chýbajúcej fľaši"])},
      "description_placeholder_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete opísať, kde sú dôvody na odmietnutie"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voliteľná stránka"])},
      "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADRESA URL"])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])},
      "missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašli sa žiadne výsledky."])}
    },
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne objednávky"])},
    "bottler_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stránka plničky"])},
    "search_explain_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžete použiť kombinácie kľúčových slov."])},
    "search_explain_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávanie nie je citlivé na veľkosť písmen."])},
    "search_explain_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie je potrebné uvádzať \"roky\"."])},
    "search_explain_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Napísanie všeobecnej kategórie liehovín (rum, whisky, koňak atď.) neprinesie žiadne výsledky."])},
    "search_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte, či máte v paneli vyhľadávania aspoň jedno kľúčové slovo."])}
  },
  "orders": {
    "swap_taker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Taker"])},
    "swap_giver": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Swap Giver"])},
    "account_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ účtu"])},
    "current_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne objednávky"])},
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smer"])},
    "creation_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum vytvorenia"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množstvo"])},
    "update_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť objednávku na nákup"])},
    "update_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť príkaz na predaj"])},
    "update_block_trade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť blokové obchodovanie"])},
    "update_swap_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť príkaz na výmenu"])},
    "display_deleted_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť vymazané objednávky"])},
    "wish_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chcel by som dostávať"])},
    "possess_a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastním"])},
    "in_exchange_of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["výmenou za"])},
    "that_trade_for": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čo by som chcel vymeniť za"])},
    "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka bola úspešne upravená"])},
    "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešne vytvorená objednávka"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková suma :"])},
    "total_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet objednávok :"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov, cena..."])}
  },
  "match": {
    "refuse_reason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dôvod odmietnutia"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmietnuť"])}
  },
  "product_page": {
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre tento odkaz sa nenašla žiadna fľaša."])},
    "add_to_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať do portfólia"])},
    "btn_buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup/predaj"])},
    "btn_swap": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výmena"])},
    "btn_blocktrade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blokové obchodovanie"])},
    "product_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie o fľašiach"])},
    "ticker_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zloženie tickerov"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "spirit_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ liehovín"])},
    "distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distillerie"])},
    "bottler": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaštička"])},
    "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vek"])},
    "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Značka"])},
    "strength": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abv"])},
    "vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vintage"])},
    "bottling_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rok plnenia do fliaš"])},
    "bottle_size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obsah"])},
    "num_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet fliaš"])},
    "num_bottles_produced": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet vyrobených fliaš"])},
    "base": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základňa"])},
    "production_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spôsob výroby"])},
    "distillery_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav liehovaru"])},
    "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Klasifikácia"])},
    "empty_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadna objednávka na túto fľašu"])},
    "composite_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zloženie tickerov"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktívne"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neaktívna stránka"])},
    "search_add_bottle_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadávanie a pridanie fľaše do grafu"])},
    "isbn_ticker_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ISBN, ticker alebo názov produktu"])},
    "bottle_not_found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadaná fľaša nebola nájdená"])},
    "trades": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "sources": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdroje"])},
    "empty_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pre tento odkaz nie je k dispozícii žiadny obrázok"])},
    "cask_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Číslo suda"])},
    "show_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazenie historických cien"])},
    "live_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Živé objednávky"])},
    "number_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počet fliaš"])},
    "orders": {
      "create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť objednávku na nákup"])},
      "create_sell_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť príkaz na predaj"])},
      "create_taker_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie príkazu Swap Taker"])},
      "create_giver_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie príkazu Swap Giver"])},
      "create_blocktrade_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie blokového obchodu"])},
      "weight_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váhová jednotka"])},
      "size_unit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veľkosť jednotky"])},
      "buy_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
      "sell_orders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
      "create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť objednávku"])},
      "individual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuálne"])},
      "pro": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pro"])},
      "mix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mix"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V súčasnosti nie sú k dispozícii žiadne objednávky."])},
      "buy_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka na nákup"])},
      "buy_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákupná cena"])},
      "buy_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množstvo"])},
      "buy_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti"])},
      "buy_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naplánovať dátum a čas ukončenia?"])},
      "buy_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť objednávku na nákup"])},
      "sell_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príkaz na predaj"])},
      "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množstvo"])},
      "sell_weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hmotnosť"])},
      "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dĺžka"])},
      "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šírka"])},
      "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výška"])},
      "sell_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať fotografie"])},
      "sell_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti"])},
      "sell_end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naplánovať dátum a čas ukončenia?"])},
      "sell_create_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť príkaz na predaj"])},
      "success_create_buy_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Úspešne vytvorená objednávka"])},
      "success_create_dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša žiadosť bola spracovaná. Čoskoro vás budeme kontaktovať"])},
      "ft_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pravidlá, ktoré treba dodržiavať ako"])},
      "buy_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhnite sa zbytočnému zaplavovaniu knihy objednávok"])},
      "buy_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obmedzte svoje objednávky na skutočné transakcie."])},
      "buy_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhnite sa opakovaným odmietnutiam"])},
      "buy_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované odmietnutie zápasu môže byť vnímané ako zlomyseľné alebo nezodpovedné. Uistite sa, že transakcie uzatvárate len vtedy, ak ich máte v úmysle dokončiť."])},
      "buy_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rešpektujte časy obnovy"])},
      "buy_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované nevyzdvihnutie zásielok načas môže viesť k nepríjemnostiam pre predávajúceho a dodatočným nákladom."])},
      "buy_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rešpektujte integritu knihy objednávok"])},
      "buy_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falšovanie alebo manipulácia s knihou objednávok, deaktivácia záujmu po odmietnutí zhody atď. sa považujú za podvodné praktiky. Uistite sa, že vaše konanie je transparentné."])},
      "buy_ft_5_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pripravte sa na súvisiace náklady"])},
      "buy_ft_5_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môžu sa uplatňovať miestne dovozné a colné dane. Pri uzatváraní transakcie sa uistite, že ste pripravení ich zaplatiť."])},
      "sell_ft_1_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytovať presné informácie"])},
      "sell_ft_1_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uistite sa, že opis fľaše je presný a pravdivý. To pomôže kupujúcim urobiť informované rozhodnutie. Uveďte celú prednú a zadnú fotografiu."])},
      "sell_ft_2_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rešpektujte podrobnosti ponuky"])},
      "sell_ft_2_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaviažete sa, že predáte fľašu, dodržíte tento záväzok. Každá ponuka, ktorá nebude dodržaná, bude mať pre predávajúceho dôsledky."])},
      "sell_ft_3_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garancia kvality balenia a jeho obsahu"])},
      "sell_ft_3_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované dodávky poškodených zásielok (znehodnotenie, rozbitie, únik) a nesprávny obsah majú na vás vplyv. Dbajte na to, aby ste svoje fľaše starostlivo zabalili."])},
      "sell_ft_4_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rešpektujte časy dodania"])},
      "sell_ft_4_content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opakované nedodanie tovaru načas môže ovplyvniť váš prístup do oblasti transakcií. Aby ste sa vyhli oneskoreniam, naplánujte si to zodpovedajúcim spôsobom."])},
      "package_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na balík:"])},
      "dispute_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu môžete podrobne opísať svoj problém, aby sa uľahčilo riešenie lístka"])},
      "confirm_shipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť prijatie zásielky"])},
      "confirm_shipment_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdením prevzatia zásielky potvrdzujete, že výrobok spĺňa vaše očakávania."])},
      "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktíva"])},
      "deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazané"])},
      "match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zápas"])},
      "match_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čakanie"])},
      "ship_send_pending_buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preprava"])},
      "ship_send_pending_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať na"])},
      "delivery_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri preprave"])},
      "delivery_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doručované"])},
      "dispute": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súdne spory"])},
      "end_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dokončené"])},
      "update_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])},
      "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dodávkach"])},
      "track_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledovacie číslo"])},
      "pay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platená stránka"])},
      "track": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledovanie mojej zásielky"])},
      "shipping_details_see": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozrite si podrobnosti o dodávkach"])},
      "in_transit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri dodávke"])},
      "order_create_block_by_match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pred vytvorením novej objednávky prijmite alebo odmietnite svoje návrhy"])},
      "blocktrade_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na Blocktrade"])},
      "blocktrade_unique_link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedinečné prepojenie na zdieľanie"])},
      "blocktrade_secure_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečná transakcia s naším poskytovateľom platieb Mangopay"])},
      "delete_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
      "shipping_download_sheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnite si dodací list"])},
      "bo_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri overovaní"])},
      "anonymous_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anonymná transakcia (3 EUR s DPH)"])},
      "max_min_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Musíte nahrať obrázky fľaše"])},
      "duplicate_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten istý obrázok nemôžete vložiť viac ako raz"])},
      "pictures_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akceptované sú len obrázky menšie ako 7 MB a s príponami .JPG, .JPEG a .png."])},
      "add_min_one_hour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša objednávka nemôže byť platná menej ako jednu hodinu"])},
      "empty_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V súčasnosti nie sú k dispozícii žiadne nákupné objednávky."])},
      "empty_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Momentálne nie sú k dispozícii žiadne príkazy na predaj."])},
      "anonymous_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujúci nebude poznať vašu identitu"])},
      "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domovská kolekcia"])},
      "pickup_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vašu zásielku vyzdvihne z vášho domova spoločnosť UPS"])},
      "pickup_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum zberu"])},
      "pickup_start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas prvého vyzdvihnutia"])},
      "pickup_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čas posledného vyzdvihnutia"])},
      "not_match_preferences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento príkaz nezodpovedá vašim preferenciám krajiny alebo preferenciám protistrany"])},
      "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať obrázky"])},
      "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alebo ťahať a pustiť"])},
      "file_format_allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Len súbory .JPG, .PNG a .JPEG (max. 7 MB na jednu fotografiu)"])},
      "pickup_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K dispozícii sú reléové body"])},
      "kyc_not_verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na prístup do sekcie transakcií musíte overiť svoju totožnosť"])},
      "kyc_redirect": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte moju identitu"])},
      "create_alert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť upozornenie"])},
      "create_alert_explain_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvorení príkazu na predaj dostanete e-mail."])},
      "create_alert_explain_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvorení objednávky na nákup dostanete e-mail."])},
      "create_alert_explain_generic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Po vytvorení objednávky dostanete e-mail."])},
      "create_alert_help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ak chcete vytvoriť upozornenie, prejdite na stránku vybraného produktu."])},
      "order_alert_price_trigger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny (voliteľné)"])},
      "order_alert_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše upozornenie bolo vytvorené"])},
      "order_alert_errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba pri vytváraní upozornenia"])},
      "order_alert_created_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša cena musí byť v rozmedzí od 50 do 30000 EUR alebo 0 EUR"])},
      "order_alert_paused": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pozastavenie tohto oznámenia"])},
      "order_alert_active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Znovu aktivujte toto upozornenie"])},
      "disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deaktivované"])},
      "blocktrade_link_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírujte odkaz na zdieľanie objednávky Blocktrade"])},
      "blocktrade_countdown": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš blokový obchod je k dispozícii maximálne 2 hodiny. Ak chcete zdieľať tento súkromný príkaz na predaj, skopírujte odkaz kliknutím na tlačidlo vyššie."])},
      "success_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odkaz na Blocktrade bol skopírovaný správne"])},
      "wrong_value_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skontrolujte si svoje vstupy"])},
      "bottle_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena za jednotku (50-30000)"])},
      "package_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozmery balíka"])},
      "add_bottle_basket": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať fľašu"])},
      "choose_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum exspirácie"])},
      "duplicate_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten istý odkaz nemôžete pridať dvakrát"])},
      "maximum_sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dosiahli ste maximálne množstvo povolené na jeden predaj"])}
    },
    "price_history": {
      "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny"])},
      "compare": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať fľašu"])},
      "responsability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grafy majú len informatívny charakter. Za prípadné chyby nenesieme žiadnu zodpovednosť."])},
      "data_info_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naše historické údaje agregujú"])},
      "data_info_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predaj"])},
      "data_info_11-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["predaj"])},
      "data_info_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
      "data_info_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rôzne zdroje."])},
      "data_info_3-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zdroj: Internetový portál."])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mena"])},
      "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky"])},
      "average": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priemerná hodnota"])},
      "high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Top"])},
      "low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bas"])},
      "base_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Základňa 100"])},
      "line_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čiarkový graf"])},
      "candlesticks_chart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Graf sviečok"])},
      "chart_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti grafu"])},
      "on": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["na stránke"])},
      "perf": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výkonnosť"])},
      "since": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["z"])},
      "delay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny sa zobrazujú s oneskorením"])},
      "delay_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesiac"])},
      "no_recent_prices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie sú k dispozícii žiadne historické údaje o cenách. Ak sme ich ešte nezaradili, obráťte sa na podporu, aby sme ich pridali."])},
      "current_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne zobrazenie"])},
      "day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesačná stránka"])},
      "year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročný"])},
      "week": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Týždenný"])},
      "compare_dialog": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Porovnajte s"])},
        "no_select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne vybrané fľaše"])},
        "same_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto fľaša je už na grafe"])},
        "limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V grafe ste dosiahli limit 6 fliaš"])}
      },
      "composite": {
        "display_composite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť zloženie"])}
      }
    },
    "report": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť chybu"])},
      "error_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Typ chyby"])},
      "select": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poznámky"])},
      "optional": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voliteľná stránka"])},
      "description_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presnejšie opíšte problém tu..."])},
      "url_placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["https://www.thexchangeplatform.io"])},
      "pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snímky obrazovky / obrázky"])},
      "pictures_max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximálne 2 obrázky."])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať"])}
    },
    "shipping_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dodávkach"])},
    "success_send_error_missing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša žiadosť bola zohľadnená"])},
    "release_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum vydania"])},
    "release_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výstupné ceny"])}
  },
  "matchs": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zápasy"])},
    "match_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhodný kupujúci"])},
    "match_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zhodný predajca"])},
    "accept_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpiť"])},
    "accept_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijímanie a predaj"])},
    "show_transactions": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Zobraziť moje ", _interpolate(_named("num_transac")), " aktuálne transakcie"])},
    "show_pictures": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť fotografie"])},
    "buying_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moja objednávka na nákup"])},
    "selling_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príkaz na predaj"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujúci"])},
    "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vo vývoji"])},
    "buying_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka na nákup"])},
    "sold_by_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Protistrana"])},
    "selling_order_seller_view": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môj príkaz na predaj"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie sú tu zobrazené žiadne zhody."])}
  },
  "account_verification_details": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie účtu a funkcie dostupné pre všetkých"])},
    "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Funkcie"])},
    "unverified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neauditovaný účet"])},
    "verified_account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overený účet"])},
    "newsfeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informačný kanál"])},
    "full_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezplatný a neobmedzený plný prístup"])},
    "price_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historické ceny"])},
    "advanced_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokročilá vizualizácia"])},
    "month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["15-mesačný odklad"])},
    "month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odložené na 12 mesiacov"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólio"])},
    "portfolio_month_15": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odložené oceňovanie o 15 mesiacov"])},
    "portfolio_month_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odložené oceňovanie o 12 mesiacov"])},
    "tickers_limit_50": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 jedinečných tickerov"])},
    "tickers_limit_100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["100 jedinečných tickerov"])},
    "database": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Databáza"])},
    "tickers_limit_daily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["50 tiketov denne"])},
    "trading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obchodovanie"])},
    "orders_visualization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť aktívne objednávky"])},
    "orders_not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadny transakčný prístup"])},
    "kyc_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Overenie totožnosti je pre fyzické osoby bezplatné a pre profesionálov spoplatnené. KYC vykonáva náš poskytovateľ platobných služieb Mangopay."])},
    "not_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neobmedzený úplný prístup"])}
  },
  "portfolio": {
    "copied_id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID skopírované"])},
    "folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor"])},
    "import_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať súbor CSV alebo Excel"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať"])},
    "bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaša"])},
    "select_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si všetky"])},
    "unselect_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstráňte všetky"])},
    "research_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhľadajte fľašu"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólio"])},
    "to_guide_you": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na sprevádzanie,"])},
    "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnuť"])},
    "load_a_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahrať súbor"])},
    "or_drag_drop": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alebo ťahať a pustiť"])},
    "file_csv_and_xlsx": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Len súbory CSV a XLSX"])},
    "an_import_template": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["model dovozu"])},
    "columns_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stĺpce"])},
    "add_button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať"])},
    "average_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priemerné obdobie držby"])},
    "portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólio"])},
    "rename": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premenovať"])},
    "move_in_another_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presun do iného priečinka"])},
    "rename_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premenovať priečinok"])},
    "empty_folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyžaduje sa názov súboru"])},
    "max_characters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximálny počet znakov nesmie presiahnuť 30"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dovozca"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parametre"])},
    "statistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štatistiky"])},
    "column_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stĺpce tabuľky"])},
    "options_table": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tabuľka možností"])},
    "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti"])},
    "foldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zložiť všetky"])},
    "unfoldAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhaľte všetky"])},
    "show_sell_stock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť predané akcie"])},
    "bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaše"])},
    "tickers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tickery"])},
    "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie"])},
    "th_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
    "th_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množstvo"])},
    "th_net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistá cena"])},
    "th_gross_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrubá cena"])},
    "price_with_commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provízia"])},
    "th_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky"])},
    "th_storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto skladovania"])},
    "th_holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Holding"])},
    "th_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prílohy"])},
    "th_buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kde nakupovať"])},
    "add_folder_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať súbor"])},
    "add_bottle_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať fľašu"])},
    "name_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov nadradeného priečinka"])},
    "additional_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšie stĺpce"])},
    "column_order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objednávka stĺpca"])},
    "my_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje fľaše"])},
    "purchaseDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum nákupu"])},
    "addPriceOptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať možnosti cien"])},
    "sellDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum predaja"])},
    "swap_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum burzy"])},
    "sale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "purchase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpiť"])},
    "no_bottles_found_text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašli sa žiadne fľaše zodpovedajúce vášmu vyhľadávaniu."])},
    "select_parent_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte nadradený priečinok"])},
    "folder_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov súboru"])},
    "create_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť účet"])},
    "modify_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymeniť fľašu"])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Popis"])},
    "running": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vo vývoji"])},
    "bottle_on_hold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držané fľaše"])},
    "bottle_on_hold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaše vo vašom vlastníctve"])},
    "unique_references_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedinečné odkazy"])},
    "on_position_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastnené fľaše vynásobené príslušnou nákupnou cenou"])},
    "AUM_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastnené fľaše vynásobené trhovou hodnotou"])},
    "running_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus valeur en cours, rozdiel medzi trhovou hodnotou a nákupnou cenou vynásobený množstvom."])},
    "running_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálna výkonnosť"])},
    "running_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálny ročný výnos"])},
    "average_price_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priemerná cena všetkých fliaš"])},
    "average_holding_length_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priemerná doba, počas ktorej zostáva držba kupujúceho v portfóliu"])},
    "bought_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše doteraz nakúpené fľaše"])},
    "sold_bottles_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše doteraz predané fľaše"])},
    "total_spent_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkové doterajšie výdavky"])},
    "total_sold_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový predaj k dnešnému dňu"])},
    "total_pnl_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kumulovaný zisk od prvého dňa do dnešného dňa"])},
    "total_pnl_period_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizovaný zisk za vybrané obdobie"])},
    "realized_total_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výnos sa počíta od prvého dňa (zohľadňujú sa len predané fľaše)"])},
    "realized_year_return_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Efektívny ročný výnos (predané fľaše)"])},
    "unique_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jedinečné odkazy"])},
    "on_position": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počiatočná hodnota pozície"])},
    "running_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálna výkonnosť"])},
    "running_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročný výnos"])},
    "realized_year_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historický ročný výnos"])},
    "realized_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historický realizovaný výnos"])},
    "average_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priemerná cena"])},
    "historical": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["História"])},
    "bought_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nakúpené fľaše"])},
    "unique_ticker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ticker je jedinečný"])},
    "AUM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocenenie"])},
    "running_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latentný zisk"])},
    "sold_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predané fľaše"])},
    "total_spent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkové výdavky"])},
    "total_pnl": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový realizovaný zisk"])},
    "total_pnl_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Realizovaný zisk (obdobie)"])},
    "realized_total_return": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historický realizovaný výnos"])},
    "total_sold": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celkový predaj"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum nákupu/predaja"])},
    "net_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistá cena"])},
    "price_without_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny bez DPH sú uvedené na stránke."])},
    "price_with_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny s poplatkami"])},
    "price_with_vat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny s DPH"])},
    "price_net": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Čistá cena"])},
    "price_gross_buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrubá nákupná cena"])},
    "price_gross_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hrubá predajná cena"])},
    "storage_location": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto skladovania"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Množstvo"])},
    "boughts": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup"])},
    "sales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "details_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenčné údaje"])},
    "are_you_sure_delete_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ste si istí, že chcete tento súbor odstrániť?"])},
    "xlsx_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor vo formáte Excel"])},
    "file_optimization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipy pre váš súbor Excel"])},
    "displayed_columns": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobrazené stĺpce"])},
    "check_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte si všetky"])},
    "stockage_usage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Využívaný priestor"])},
    "add_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvoriť účet"])},
    "add_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať fľašu"])},
    "import_file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importovať súbor"])},
    "current_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálna fľaša"])},
    "current_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne fľaše"])},
    "current_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálna referencia"])},
    "current_references": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne odkazy"])},
    "current_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálna dokumentácia"])},
    "move_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presun do priečinka"])},
    "move_bottles_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaše v priečinku"])},
    "move_references_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["odkazy v dokumentácii"])},
    "move_bottle_to_folder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presun do priečinka"])},
    "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky"])},
    "holding_length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držba (deň)"])},
    "files_attachments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prílohy"])},
    "buy_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kde nakupovať"])},
    "sell_place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miesto predaja"])},
    "sell_quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predané množstvo"])},
    "wab_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cena WAB"])},
    "wasd_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocenenia WASD"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategória"])},
    "sub_asset_class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trieda aktív"])},
    "see_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejsť na kartu produktu"])},
    "last_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posledná cena"])},
    "volatility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita"])},
    "uncheck_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyberte všetky"])},
    "selected_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vybrané fľaše"])},
    "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presun"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať"])},
    "delete_bottle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať fľašu"])},
    "tel_not_available": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólio je v súčasnosti dostupné len na počítači, ale čoskoro bude k dispozícii aj na mobilných zariadeniach."])},
    "volatility_portfolio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita portfólia"])},
    "volatility_spirits_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita liehovín"])},
    "volatility_distillery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Volatilita liehovaru"])},
    "volatility_vintage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročník volatility"])},
    "volatility_age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vek volatility"])},
    "portfolio_soon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfólio premium už čoskoro"])},
    "see_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zobraziť podrobnosti"])},
    "go_to_product_page": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prejsť na stránku produktu"])},
    "folder_created": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorený súbor"])},
    "folder_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor bol upravený"])},
    "folder_deleted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor bol vymazaný"])},
    "folder_created_error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chyba pri vytváraní súboru"])},
    "bottle_add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bola pridaná fľaša"])},
    "bottle_updated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaša bola upravená"])},
    "bottle_move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fľaša bola presunutá"])},
    "file_send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Súbor bol úspešne importovaný"])},
    "move_modal_title_origin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pôvodný súbor :"])},
    "move_modal_content_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tento priečinok a všetky fľaše v ňom budete presúvať."])},
    "move_folder_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Váš súbor bol presunutý"])},
    "folder_contain_bottles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie je možné odstrániť, pretože v tomto priečinku sú fľaše"])}
  },
  "bid": {
    "send_offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslať ponuku"])}
  },
  "create_order": {
    "file_regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Povolené sú len súbory .jpg, .jpeg a .png s veľkosťou menšou ako 3 MB."])}
  },
  "payment_process": {
    "transaction_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o transakciách"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad objednávok"])},
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Náklady na prepravu"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daň"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpiť"])},
    "sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predaj"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmietnuť"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predávajúci :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručenie"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakčné poplatky"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková suma"])},
    "price_tva_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrátane DPH na"])},
    "shipping_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručenie UPS"])},
    "payment_type_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spôsob platby"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spôsob platby"])},
    "inssurance_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poistenie UPS"])},
    "ups_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS doma"])},
    "ups_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UPS point relais"])},
    "relay_choice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["K dispozícii sú miesta výberu"])},
    "save_relay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uložte si túto adresu pre budúce nákupy"])},
    "failed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcia sa nepodarila. Skúste to prosím znova"])},
    "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transakcia bola úspešne dokončená"])},
    "payment_pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaša platba sa overuje"])},
    "payment_please_wait": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Počkajte niekoľko sekúnd"])},
    "add_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať do košíka"])},
    "remove_to_cart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odstrániť z košíka"])}
  },
  "settings": {
    "title_menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavenia účtu"])},
    "title_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa predplatného"])},
    "my_subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moje predplatné"])},
    "payment_method": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spôsob platby"])},
    "invoice_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["História faktúr"])},
    "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Môj účet"])},
    "buy_sell": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nákup a predaj"])},
    "bank_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankové údaje"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bezpečnosť a 2FA"])},
    "personnal_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informácie"])},
    "business": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť"])},
    "civility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Civility"])},
    "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štátna príslušnosť"])},
    "lastname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "firstname": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krstné meno"])},
    "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum narodenia"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefónne číslo"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresa"])},
    "zipcode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poštové smerovacie číslo"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "region": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Región"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina"])},
    "other_delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať ďalšiu adresu na doručenie"])},
    "delivery_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dodacia adresa"])},
    "delivery_delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vymazať adresu na doručenie"])},
    "billing_address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fakturačná adresa"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])},
    "verified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslané na"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Žiadne informácie"])},
    "fullnames": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Držiteľ účtu"])},
    "created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odoslané na"])},
    "update_rib": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmeňte svoj RIB"])},
    "2fa_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojité overenie"])},
    "2fa_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pri každom prihlásení a pri každej zmene na účte dostanete kód."])},
    "2fa_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojité overenie prostredníctvom e-mailu"])},
    "2fa_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dvojité overenie prostredníctvom SMS"])},
    "login_history_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Najnovšie pripojenia"])},
    "login_history_subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na všetkých zariadeniach si môžete prezerať históriu posledných 10 pripojení k svojmu účtu."])},
    "newsletter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostávajte informačný bulletin"])},
    "card_numbers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Debetná karta č."])},
    "card_created_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridané na"])},
    "card_update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upraviť"])},
    "card_expiration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platnosť končí na"])},
    "card_update_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmena bankovej karty"])},
    "card_empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zatiaľ ste si nezaregistrovali platobnú kartu."])},
    "delivery_choice_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nastavte túto adresu ako predvolenú"])},
    "delivery_is_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uprednostňovaná adresa na doručenie"])},
    "update_dialog": {
      "title_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmena e-mailovej adresy"])},
      "actual_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálna e-mailová adresa"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nová e-mailová adresa"])},
      "title_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmena telefónneho čísla"])},
      "actual_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuálne telefónne číslo"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nové telefónne číslo"])},
      "title_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pridať adresu na doručenie"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaregistrujte sa na stránke ."])},
      "err_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail nesprávne"])},
      "err_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nesprávne telefónne číslo"])},
      "err_email_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Túto e-mailovú adresu už máte"])},
      "err_phone_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toto telefónne číslo už máte"])}
    },
    "subscriptions": {
      "last_month_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výdavky v aktuálnom mesiaci"])},
      "sub_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odberateľ od"])},
      "never_sub": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nie ste predplatiteľom"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ročné predplatné"])},
      "next_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum ďalšieho odberu"])},
      "subscription_term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predplatené do"])},
      "cancel_subscription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásiť sa z odberu"])},
      "explore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preskúmajte dostupné predplatné historických cien"])},
      "cancel_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhlásiť sa z odberu"])},
      "cancel_access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše predplatné vyprší dňa"])},
      "cancel_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predplatné sa týka"])},
      "cancel_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť zrušenie"])},
      "reactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnovte predplatné"])},
      "reactive_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pokračovať v predplatnom"])},
      "reactive_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ďalšia platba sa uskutoční dňa"])},
      "reactive_informations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predplatné sa týka"])},
      "reactive_period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba sa uskutoční každý"])},
      "reactive_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["za cenu"])},
      "reactive_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdenie oživenia"])},
      "commitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Záväzok do"])},
      "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mesiac"])},
      "yearly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
      "premium": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prémiové historické ceny"])}
    },
    "invoices": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["História faktúr"])},
      "download_all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnite si všetky"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dátum"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ceny"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav"])},
      "subscriptions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predplatné"])},
      "download": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stiahnite si faktúru"])},
      "paid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platená stránka"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nemáte k dispozícii faktúru"])},
      "delivery_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podrobnosti o dodávkach"])},
      "track_package": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sledovanie balíka"])},
      "delivery_estimation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odhadovaná dodávka"])},
      "transaction_number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Č. transakcie"])}
    },
    "confirm": {
      "subtitle_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód, ktorý ste dostali e-mailom"])},
      "subtitle_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód prijatý telefonicky"])},
      "new_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový e-mail"])},
      "new_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nový telefón"])},
      "code_email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód, ktorý ste dostali e-mailom"])},
      "code_phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zadajte kód prijatý telefonicky"])}
    },
    "billing_address_delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faktúra a adresa dodania"])},
    "preferences_save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše preferencie boli úspešne zaregistrované"])},
    "birth_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mesto narodenia"])},
    "birth_country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajina narodenia"])},
    "preferences_mails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oznámenia"])},
    "preference_t_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie objednávky na nákup"])},
    "preference_t_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie príkazu na predaj"])},
    "preference_t_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie výmenného príkazu"])},
    "preference_t_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vytvorenie príkazu na blokové obchodovanie"])},
    "preference_t_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kupujúci odmietol zhodu"])},
    "preference_t_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Príkaz na predaj čaká na potvrdenie"])},
    "preference_t_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorizácia prijímania SMS (overenie účtu a/alebo 2FA)"])},
    "preferences": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Komunikačné preferencie"])}
    },
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Názov"])},
    "relay_ups": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štafetový bod UPS"])},
    "buySellPreferences": {
      "buying_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti nákupu"])},
      "selling_options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Možnosti predaja"])},
      "buying_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditeľnosť nákupu"])},
      "selling_visibility": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viditeľnosť predaja"])},
      "country_exclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajiny, ktoré sa majú vylúčiť"])},
      "country_inclusion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Krajiny, ktoré sa majú zahrnúť"])},
      "preferences_copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Skopírujte moje nákupné preferencie pre predaj"])},
      "save_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše zmeny boli zohľadnené"])}
    }
  },
  "footer": {
    "social_medias": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociálne siete"])},
    "support": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podpora"])},
    "legals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právna stránka"])},
    "help_center": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrum pomoci"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ČASTO KLADENÉ OTÁZKY"])},
    "recruitment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nábor"])},
    "bug_report": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahlásiť chybu"])},
    "service_status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stav služieb"])},
    "legal_notice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Právne informácie"])},
    "cgu_cgv": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PODMIENKY"])},
    "cookies": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Správa súborov cookie"])},
    "tep_footer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spoločnosť registrovaná vo Francúzsku"])}
  },
  "paiment_process": {
    "shipping_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručenie"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dane"])},
    "buy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kúpiť"])},
    "refuse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odmietnuť"])},
    "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prehľad objednávok"])},
    "sold_by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predávaná časť :"])},
    "delivery_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za doručenie"])},
    "selling_fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poplatky za predaj"])},
    "total_price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková suma"])}
  },
  "transactions": {
    "direction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sens"])},
    "dispute_in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prebiehajúce súdne spory"])},
    "dispute_create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vyhlásiť spor"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevykonávajú sa žiadne transakcie."])}
  },
  "blog": {
    "reset_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obnoviť filtre"])},
    "published_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zverejnené na :"])},
    "updated_at": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upravené na :"])},
    "articles_filters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrovanie článkov"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nenašli sa žiadne položky."])}
  },
  "wallet": {
    "available_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dostupné množstvo"])},
    "waiting_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na počkanie"])},
    "total_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Celková suma"])},
    "withdraw_money": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber"])},
    "money_movement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pohyby"])},
    "payin_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vykonané platby"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nezobrazujú sa žiadne transakcie."])},
    "transfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevod"])},
    "transfert_in": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prichádzajúci prevod"])},
    "transfert_out": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Odchádzajúci prevod"])},
    "payout_seller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba predávajúceho"])},
    "bank_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber"])},
    "bank_payout_in_infos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber na bankový účet"])},
    "payout_modal_title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Výber dostupných finančných prostriedkov"])},
    "payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Potvrdiť výber"])},
    "success_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevod na váš bankový účet bol úspešne dokončený"])},
    "failed_payout_confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prevod na váš účet sa nepodaril. Ak problém pretrváva, obráťte sa na zákaznícky servis"])},
    "order_wallet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moja peňaženka (0 %)"])},
    "available_after_transaction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma zostávajúca po transakcii :"])},
    "payin_received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prijaté platby"])},
    "transactions_history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prístup k histórii obchodovania"])},
    "send_iban_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previesť peniaze priamo do peňaženky a vyhnúť sa tak poplatkom za platby"])},
    "wallet_owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník :"])},
    "wallet_iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN :"])},
    "wallet_bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC :"])},
    "wallet_await_funds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vaše finančné prostriedky budú k dispozícii do 2 až 3 pracovných dní."])},
    "copy_success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prvok úspešne skopírovaný"])},
    "copy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kopírovať"])},
    "wallet_supply": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poskytovanie peňaženky"])},
    "instant_payout_default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Platba sa štandardne uskutočňuje okamžitým prevodom"])},
    "available_amount_payout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suma, ktorá je k dispozícii na výber :"])},
    "banking_account_end": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bankový účet končiaci na :"])}
  },
  "filters": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozšírené filtre"])},
    "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filter"])},
    "filters_conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Na vyhľadávanie bez kľúčových slov sú potrebné 2 filtre"])}
  }
}